<template>
    <div class="container">
        <section v-for="(item, index) in items" :key="index" :class="{ 'reverse': index % 2 }">
            <div class="image">
                <img :src="item.image" :alt="`Image ${index + 1}`">
            </div>
            <div class="text">
                <p>{{ item.text }}</p>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'ImageTextSections',
    data() {
        return {
            items: [
              
                { image: require('@/assets/charliecunt.png'), text: 'Not a human, or a man.' },
                { image: require('@/assets/donnysad.png'), text: 'Not a human, or a man.' },
                { image: require('@/assets/rapistfuckwad.png'), text: 'Not a human, or a man.' },
                { image: require('@/assets/sloppyben.png'), text: 'Not a human, or a man.' },
            ],
        };
    },
};
</script>

<style>
.container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    height: 100vh;
    /* Adjust based on your layout */
}

section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start;
    height: 100vh;
    /* Adjust based on your layout */
}

section.reverse {
    flex-direction: row-reverse;
}

.image img {
    width: 50%;
    /* Adjust based on your layout */
}

.text {
    width: 50%;
    /* Adjust based on your layout */
    padding: 20px;
}
</style>